import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'components/Icon';

import styles from './styles.module.scss';
// @TODO: theme newDesign maked default theme (when apply changes for all page)
const HeadTitle = ({ text, url, align, theme, size, className }) => {
	const headTitleCx = cx(
		styles.title,
		styles[`size_${size}`],
		styles[`align_${align}`],
		styles[`theme_${theme}`],
		className
	);

	return (
		<div className={headTitleCx}>
			{url ? (
				<a href={url} className={styles.link}>
					{text}
					<Icon icon="chevronRight" className={styles.icon} />
				</a>
			) : (
				<span className={styles.text}>{text}</span>
			)}
		</div>
	);
};

HeadTitle.propTypes = {
	text: PropTypes.string.isRequired,
	url: PropTypes.string,
	theme: PropTypes.oneOf(['live']),
	align: PropTypes.oneOf(['center']),
	size: PropTypes.oneOf(['medium', 'default', 'large']),
	className: PropTypes.string
};

HeadTitle.defaultProps = {
	size: 'default'
};

export default HeadTitle;
