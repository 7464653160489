import _ from 'underscore';
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { LayoutBlock } from 'components/Layout';
import BaseSlider from 'components/sliders/BaseSlider';
import Container from 'components/Container';
import Section from 'components/Section';

import { SCREEN_BREAKPOINTS } from 'utils/helpers/constants';

const SimilarEntities = memo(
	({ Card, cardParams, entities, isPortraitCard, title, url }) => {
		const sliderParams = useMemo(
			() => ({
				slidesPerView: 'auto',
				spaceBetween: 8,
				breakpoints: {
					[SCREEN_BREAKPOINTS.tabletLarge]: {
						slidesPerView: isPortraitCard ? 6 : 4,
						spaceBetween: 24
					},
					[SCREEN_BREAKPOINTS.desktopMedium]: {
						slidesPerView: isPortraitCard ? 7 : 5,
						spaceBetween: 24
					}
				}
			}),
			[isPortraitCard]
		);

		const renderSlides = () => [
			..._(entities).map((entity) => (
				<Card key={entity._id} entity={entity} {...cardParams} />
			))
		];

		return (
			<LayoutBlock theme="light" spacing="similar" noPrint>
				<Section title={title || 'Смотрите также'} url={url}>
					<Container width="wideSlider">
						<BaseSlider theme="full" params={sliderParams} isNewDesign>
							{renderSlides()}
						</BaseSlider>
					</Container>
				</Section>
			</LayoutBlock>
		);
	}
);

SimilarEntities.propTypes = {
	Card: PropTypes.func.isRequired,
	cardParams: PropTypes.object,
	entities: PropTypes.arrayOf(PropTypes.object).isRequired,
	isPortraitCard: PropTypes.bool,
	title: PropTypes.string,
	url: PropTypes.string
};

export default SimilarEntities;
